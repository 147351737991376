import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: { "Content-Type": "application/json" },
});

class viewOutput {

	viewModelOutput = (payload) => {
		const queryParams = `?ids=${payload.ids}&email=${payload.email}`

		return axiosInstance
			.get(`/upload/get_tag_data${queryParams}`)
			.then((response) => {
			return response;
			})
			.catch((err) => {
			throw err;
			});
		}

	updateTagFeedback = (payload) => {
		return axiosInstance
			.post("/upload/update_tag_feedback", payload)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

	download_tags = (payload) => {
		return axiosInstance
			.post(`/upload/download_tags`, payload, {
				headers: { 'Content-Type': 'application/json' },
				responseType: 'blob'
			})
			.then(async (response) => {
				const blob = new Blob([response.data], {
					type: response.headers["content-type"],
				});
				const contentType = response.headers["content-type"];
				let fileExtension = "";
				if (
					contentType ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				) {
					fileExtension = "xlsx";
				} else if (contentType === "application/zip") {
					fileExtension = "zip";
				} else {
					console.error("Unknown file type:", contentType);
					return;
				}

				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `Tags_data.${fileExtension}`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);

				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

}

export default new viewOutput();
