import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import authenticationService from "../../api/authenticationService";
import { Box, Center, Text, Link } from "@chakra-ui/react";
import LoadingOverlay from '../../reusable-components/LoadingOverlay/LoadingOverlay';
import logo from "../../assets/Images/IntelliSearch-Black.svg";

const VerifyEmailList = () => {
	const location = useLocation();
	const [message, setMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;

			// Function to get the token from the URL path
			const queryParams = new URLSearchParams(location.search);
			const token = queryParams.toString(); // Get the entire query string

			if (token) {
				signupEmailVerification(token);
			} else {
				setMessage("No token found in the URL.");
				setIsLoading(false);
			}
		}
	}, [location.search]);

	const signupEmailVerification = async (token) => {
		try {
			const res = await authenticationService.signupEmailVerification(token);
			if (res.status === 200) {
				console.log("Verification response:", res);
				if(res.data.message === "Signup Verification successfully"){
					setMessage("Thank you for verifying your email!");
				}else if(res.data.message === "User is already verified"){
					setMessage("Your account is already verified. If you encounter any issues, please contact your administrator.");
				}
			}
		} catch (err) {
			// Log the error for debugging
			console.log("Error during verification:", err);

			if (err.response && err.response.data && err.response.data.detail) {
				if (err.response.data.detail === '404: Invalid or expired token') {
					// Token is expired or invalid
					setMessage("The verification link is expired.");
				} else {
					// Log and display any other specific error messages from the backend
					console.log("Other error:", err.response.data.detail);
				}
			} else {
				// Handle unexpected errors that don't match the expected structure
				console.log("Unexpected error:", err);
			}
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <LoadingOverlay message="Verifying email..."/>;
	}

	return (
		<div id="log-container">
			<div className="login-form" style={{background: "linear-gradient(180deg, rgba(207, 207, 207, 0.7) 0%, rgba(255, 255, 255, 0) 64.17%)" }}>
				<img className="intellitag-logo" src={logo} alt="logo" style={{ width: "54%", height: "auto" }}/>
				<Center>
					{message && (
						<Box textAlign="center" color={'#fff'}>
							<Text fontSize="xl">{message}</Text>
							{message !== "The verification link is expired." && (
								<h5>
									Click here to go to the
									<Link href="/" color="teal.500" fontSize="xl"> Login page</Link>
								</h5>
							)}
						</Box>
					)}
				</Center>
			</div>
		</div>
	);
};

export default VerifyEmailList;
