import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserForm from "../../reusable-components/userform/UserForm";
// import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { User } from "../../types";
import { BiPencil, BiToggleLeft, BiToggleRight } from "react-icons/bi";
import "./UsersList.css";
import { trackPromise } from "react-promise-tracker";
import usercreditsService from "../../api/userService";
import DataTable from "react-data-table-component";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import { Toast, ToastContainer } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Button } from "@chakra-ui/react"
import { Flex, useToast } from "@chakra-ui/react";
import { RxCross1 } from "react-icons/rx";
import { interceptorsSetup } from "../../api/intreceptor";
import departmentService from "../../api/departmentService";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

const UsersList = ({ setIsAdmin, signOutClickHandler }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateUserObj, setUpdateUserObj] = useState<User | null>(null);
  const [users, setUsers] = useState([])
  const [creditPoolBalance, setCreditPoolBalance] = useState<number>(0);
  const [useCreditPool, setUseCreditPool] = useState<boolean>(false);
  const [loaderMessage, setLoaderMessage] = useState("Fetching Users...");
  const userEmail = localStorage.getItem("email");
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [toastType, setToastType] = useState("info");
  const [toastText, setToastText] = useState("");
  const [isFeedActive, setIsFeedActive] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[] | []>([]);
  const msalResponse = localStorage.getItem("msalResponse");
  const [activeDepartments, setActiveDepartments] = useState([])
  const [totalAdmins, setTotalAdmins] = useState(null)
  const [totalActiveUser, setTotalActiveUser] = useState(null)
  const {fetchConfigSetting} = useFetchConfigSetting()
  const toast = useToast();


  const navigate = useNavigate();
  const columns = [
    {
      name: "User Name",
      width: "150px",

      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      width: "200px",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
    //   width: "90px",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Credits Assigned",
      width: "160px",
      selector: (row) => row.credit_assigned,
      sortable: true,
    },
    {
      name: "Credits Remaining",
      width: "174px",
      selector: (row) => row.credit_balance?.toFixed(1),
      sortable: true,
    },
    {
      name: "Image Uploaded",
      width: "160px",
      selector: (row) => row.image_uploaded,
      sortable: true,
    },
    {
      name: "Image Searched",
      width: "160px",
      selector: (row) => row.image_searched,
      sortable: true,
    },
    {
      name: "Status",
    //   width: "100px",
      selector: (row) =>
        row.status === 1 ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
    //   width: "110px",
      cell: (row: User) => (
        <>
          <BiPencil
            className="fa fa-pencil pointer action-icon-edit"
            style={{ color: "#222", marginRight: "10px", cursor: "pointer" }}
            title="Edit User"
            onClick={() => updateUser(row)}
          />

          {row.status === 1 ? (
            <BiToggleRight
              className="fa fa-solid fa-toggle-on text-success pointer action-icon"
              style={{ color: "#222", cursor: "pointer" }}
              title="Deactivate User"
              onClick={() => changeUserStatus({ status: 0, email: row.email })}
            />
          ) : (
            <BiToggleLeft
              className="fa fa-solid fa-toggle-off text-danger action-icon"
              style={{ color: "#222", cursor: "pointer" }}
              title="Activate User"
              onClick={() => changeUserStatus({ status: 1, email: row.email })}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const tableStyles = {
    head: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#FFFFFF",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
      },
    },
  };

  const NoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <strong>No users found</strong>
    </div>
  );

  const getDepartments = async () => {
    try{
     const res = await departmentService.getActiveDepartment();
      if(res.status === 200){
        setActiveDepartments(res.data.departments)
      }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("err:",err)
    }
  }

  const changeUserStatus = async ({ status, email }) => {
    const countActiveUser = users.filter(user => user.status === 1).length;
    setTotalActiveUser(countActiveUser)
    
    let confirmDelete = false;
    if (status == 1) {
      confirmDelete = window.confirm(
        "Are you sure you want to activate this user?"
      );
    }
    else if(status !== 1 && totalActiveUser == 1){
      confirmDelete = window.confirm(
        "Only one Active User left. Are you want to Deactivate this user ?"
      );
    }
    else {
      confirmDelete = window.confirm(
        "Are you sure you want to Deactivate this user?"
      );
    }
    if(confirmDelete){
      try{
        setLoading(true)
        const res = await usercreditsService.changeUserStatus({status, email});
        if(res.status === 200){
          setUsers(res.data.users);
          setFilteredUsers(res.data.users);
          const countActiveUser = res.data.users.filter(user => user.status === 1).length;
          setTotalActiveUser(countActiveUser)
        }
      }catch(err){
        toast({
          title: `${err.response.data.detail}`,
          position: "top",
          status: "error",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
        console.log("change status err:", err)
      }finally{
        setLoading(false)
      }
    }
  };

  const fetchUsers = () => {
    setLoading(true);
    trackPromise(
      usercreditsService
        .fetchUsers()
        .then((response) => {
          const { config, users } = response.data;

          users?.map((user) => {
            if (user.email === userEmail) {
              const isAdmin = user.role === "Admin" || user.role === "superAdmin";
              if (!isAdmin) {
                setIsAdmin(false);
                navigate("/layout");
              }
            }
          });

          const countAdmins = users.filter(user => user.role === 'Admin').length;
          setTotalAdmins(countAdmins);
          const countActiveUser = users.filter(user => user.status === 1).length;
          setTotalActiveUser(countActiveUser)

          setUsers(users);
          setFilteredUsers(users);
          setCreditPoolBalance(config.credit_pool_balance);
          setUseCreditPool(config.use_credit_pool);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        })
    );
  };

  const SetUserAfterUpdate = (updatedUser) => {
    setUsers(updatedUser);
    setFilteredUsers(updatedUser);
  }

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate])

  useEffect(() => {
    fetchUsers();
    getDepartments();
    fetchConfigSetting()
  }, []);

  useEffect(() => {
    const matchingUsers = users.filter((user: User) => {
      return (
        user.name.toLowerCase().match(search.trim().toLowerCase()) ||
        user.email.toLowerCase().match(search.trim().toLowerCase()) ||
        user.role.toLowerCase().match(search.trim().toLowerCase())
      );
    });
    setFilteredUsers(matchingUsers);
  }, [search]);

  const createUser = () => {
    setIsCreateUser(true);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setIsCreateUser(false);
    setIsUpdateUser(false);
    setUpdateUserObj(null);
  };

  const updateUser = (userObj: User) => {
    setIsCreateUser(false);
    setIsUpdateUser(true);
    setUpdateUserObj(userObj);
    setShowModal(true);
  };

  const setToastMessage = (type, msg) => {
    setToastType(type);
    setToastText(msg);
    setDisplayToast(true);
  };

  const signOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  };
  const toggleToast = () => setDisplayToast(!displayToast);
  const onClickHandler = msalResponse ? signOutClickHandler : signOut;

  // const feedClass = isFeedActive ? 'feed-active' : '';

  return (
    <div className="main-container">
      <Flex
        padding={"0px 0px 3px 0px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"0.5px solid #BDBDBD"}
        mt={{base:"8px",md:"12px",lg:"auto"}}
        mb={{ base: "6px", md: "9px", lg: "12px" }}
      >
        <Flex gap={"12px"} alignItems={"center"}>
        <h2>Users</h2>
        {useCreditPool && (
          <div className="credit-pool-balance">
            Credit Pool Balance: {creditPoolBalance}
          </div>
        )}
        </Flex>

        <NavLink to="/layout" className="crossBtn">
            <span><RxCross1/></span>
        </NavLink>
      </Flex>

      {/* <div className="row-element main-body-container">
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {useCreditPool && (
            <div className="credit-pool-balance">
              Credit Pool Balance: {creditPoolBalance}
            </div>
          )}
        </div>
      </div> */}
      <Flex alignItems={"center"} gap={{base:"10px",md:"15px",lg:"20px"}}>
          <form autoComplete="off" style={{width:"100%"}}>
            <input
              className="form-control"
              type="search"
              placeholder="Search username, email, role"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
          <Button onClick={createUser} id="btn" size={"sm"}>
            <span className="plusIcon"><FaPlus /></span> Add 
          </Button>
      </Flex>
      <div className="users-list">
        {showModal && (
          <UserForm
            isCreateUser={isCreateUser}
            isUpdateUser={isUpdateUser}
            closeModal={closeModal}
            modalHeader={isCreateUser ? "Create User" : "Update User"}
            userObj={updateUserObj}
            fetchUsers={fetchUsers}
            useCreditPool={useCreditPool}
            creditPoolBalance={creditPoolBalance}
            setCreditPoolBalance={setCreditPoolBalance}
            setToastMessage={setToastMessage}
            setIsFeedActive={setIsFeedActive}
            SetUserAfterUpdate= {SetUserAfterUpdate}
            updatedUsersData={filteredUsers}
            activeDepartments={activeDepartments}
            totalAdmins={totalAdmins}
          />
        )}
      </div>
      <div className="table-data">
        {loading && <LoadingOverlay message={loaderMessage} />}
        { !loading &&
          <DataTable
            persistTableHead={true}
            columns={columns}
            data={filteredUsers}
            noDataComponent={<NoDataComponent />}
            customStyles={tableStyles}
            pagination
          />
        }
        {
          <ToastContainer
            className="p-3"
            position={"top-end"}
            style={{ zIndex: 9999 }}
          >
            <Toast
              show={displayToast}
              onClose={toggleToast}
              bg={toastType}
              delay={5000}
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Message</strong>
              </Toast.Header>
              <Toast.Body style={{ color: "#FFFFFF" }}>{toastText}</Toast.Body>
            </Toast>
          </ToastContainer>
        }
      </div>
    </div>
  );
};

export default UsersList;
