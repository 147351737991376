const msalConfig = {
    auth: {
      clientId: 'cf231b46-8074-4a41-999e-cf19f99f766f',
      authority: 'https://login.microsoftonline.com/9be81a95-7870-42f4-bb8d-a44ada88130a',
      redirectUri: 'https://intellisearch.origamis.ai/layout',
      postLogoutRedirectUri: 'https://intellisearch.origamis.ai/login'
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie:false
    }
  };

  export default msalConfig;