import React from 'react'
import { useToast, Box, Flex, IconButton, Text, Icon } from "@chakra-ui/react";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MdInfo } from "react-icons/md";

const CustomToast = (props) => {
	const { title, description, status, onClose } = props;

	const statusColor = {
		success: "green.500",
		error: "red.500",
		warning: "yellow.500",
		info: "blue.500",
	};

	const statusBgColor = {
		success: "green.100",
		error: "red.100",
		warning: "yellow.100",
		info: "blue.100",
	};

	const statusIcon = {
		success: AiOutlineCheckCircle,
		error: BsExclamationCircleFill,
		warning: BsExclamationCircleFill,
		info: MdInfo,
	};

	return (
		<Box display="flex" alignItems="flex-start" p={'6px 2px 6px 8px'}
			bg={statusBgColor[status]} borderRadius="md" shadow="md" borderLeftWidth="4px"
			borderLeftColor={statusColor[status]} minWidth="300px" position="relative"
		>
			<Icon as={statusIcon?.[status]} w={5} h={5} color={statusColor?.[status]} mr={3} mt={"3px"} />
			<Box flex="1" pr={8} mr={"4px"}>
				{title && <Text fontWeight="bold" color="gray.800" mb={"1px"} wordBreak="break-word"> {title} </Text>}
				{description && <Text fontSize="sm" color="gray.800" wordBreak="break-word"> {description} </Text>}
			</Box>
			<IconButton size="sm" aria-label="Close" icon={<RxCross1 />} onClick={onClose} variant="ghost"
				color="gray.900" position="absolute" top="3px" right="8px" _hover={{ bg: "none" }}
			/>
		</Box>
	);
}

export default CustomToast