import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Text, useDisclosure, useToast, FormControl, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import Styles from "../../reusable-components/ImageDrop/DropZone.module.css";
import SwitchStyle from "./ToggleSwitch.module.css";
import { GoFileDirectory } from "react-icons/go";
import uploadService from "../../api/uploadService";
import useCredit from "../../hooks/useCredit";
import { BarLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { interceptorsSetup } from '../../api/intreceptor';
import taxonomyService from '../../api/taxonomyService';
import LoadingOverlay from '../../reusable-components/LoadingOverlay/LoadingOverlay';
import { CentralizeStore } from '../../contexts/CentralizeStoreProvider';


const UploadZip = (props) => {
	const { isOpen, onOpen, onClose, modalContent, handleReFetch } = props;
    const {isUploading, setIsUploading} = useContext(CentralizeStore);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isPublic, setIsPublic] = useState(false);
	const fileInputRef = useRef(null);
	const email = localStorage.getItem("email");
	const [isLoading, setIsLoading] = useState(false);
	const { setCreditBalance } = useCredit();
	const toast = useToast();
	const [domainSelection, setDomainSelection] = useState("automatically")
	const [allDomain, setAllDomain] = useState([]);
	const [isLoadingDomain, setIsLoadingDomain] = useState(false);
	const [selectedDomain, setSelectedDomain] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		interceptorsSetup(navigate);
	}, [navigate]);

	useEffect(() => {
		getAllDomains();
	}, []);


	const handleFileChange = (e) => {
		const filesArray = Array.from(e.target.files);
		setSelectedFiles(filesArray);
	};

	const handlePrivacyChange = (e) => {
		setIsPublic(e.target.checked);
	};

	const getAllDomains = async () => {
		try {
			setIsLoadingDomain(true);
			const response = await taxonomyService.getDomain();
			setAllDomain(response.taxnonmies);
		} catch (err) {
			console.log("err:", err);
		} finally {
			setIsLoadingDomain(false);
		}
	};

	const handleDomainChange = (e) => {
		setSelectedDomain(e.target.value)
	};

	const handleFileUpload = async (e) => {
		e.preventDefault();

		// Prepare data object to return
		const formData = new FormData();
		formData.append('email', email);
		//   formData.append('scope', isPublic ? '1' : '0');
		formData.append('scope', '0');
		formData.append('files', selectedFiles[0]);
		if (selectedDomain && selectedDomain.length > 0) {
			formData.append("domain_name", selectedDomain)
		}

		try {
			setIsUploading(true)
			setIsLoading(true);
			const response = await uploadService.uploadZip(formData);
			if (response.status === 200) {
				toast({
					title: `${response.data.message}`,
					position: "top",
					status: "success",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				})
				setCreditBalance(response.data.balance)
				handleReFetch()
			}
			setSelectedFiles([]);

			onClose();
		} catch (err) {
			console.log("Upload error:", err);
		} finally {
			setSelectedDomain("")
			setDomainSelection("automatically")
			setIsUploading(false)
			setIsLoading(false);
		}
	};

	const totalSelectedFilesCount = selectedFiles.length;
	const selectedFilesNames = selectedFiles.map(file => file.name).join(', ');

	// if (isLoading) {
	// 	return <LoadingOverlay message="Uploading..." />
	// }
	
	return (
		<form onSubmit={handleFileUpload} className={SwitchStyle.uploadFormCon}>
			<Flex
				flexDir="column"
				alignItems="self-start"
				justifyContent="flex-start"
			>
				<div style={{ width: "100%" }} className={Styles.dropZoneArea} onClick={() => fileInputRef.current.click()}>
					<input
						type="file"
						accept=".zip"
						multiple
						onChange={handleFileChange}
						ref={fileInputRef}
						style={{ display: "none" }}
					/>
					<Flex alignItems={"center"} gap={"5px"} color={"#828282"}>
						<GoFileDirectory />
						<span>Browse Zip</span>
					</Flex>
				</div>
				{
					totalSelectedFilesCount > 0 && <Flex flexDir={"column"} mb={2}>
						<Text color={"rgb(0 143 143)"} fontSize={"12px"} fontWeight={"500"} m={"0px"}>File selected {totalSelectedFilesCount} :</Text>
						<Text fontSize={"11px"} mb={"1px"} wordBreak={"break-word"} fontWeight="500" color="#4F4F4F">{selectedFilesNames}</Text>
					</Flex>
				}
				{/* <Flex width={"100%"} alignItems="center" justifyContent="center">
				<span>Private</span>
				<div className={SwitchStyle.checkbox_apple}>
				  <input
					className={SwitchStyle.yep}
					id="check-apple"
					type="checkbox"
					checked={isPublic}
					onChange={handlePrivacyChange}
				  />
				  <label htmlFor="check-apple"></label>
				</div>
				<span>Public</span>
			  </Flex> */}
				<Flex w={"100%"} flexDir={"column"}>
					<Text m={"0px"} fontWeight={500} fontSize={"15px"}>Domain Selection <span className="required_sym">*</span></Text>
					<RadioGroup onChange={setDomainSelection} value={domainSelection}>
						<Stack direction="row" padding={"5px 0px"}>
							<Radio value="automatically">Automatically by AI</Radio>
							<Radio value="manually">Manually</Radio>
						</Stack>
					</RadioGroup>
					{(domainSelection === 'manually') && <>
						<Text mb={"5px"} fontWeight={500} fontSize={"15px"}>Select Domain <span className="required_sym">*</span></Text>
						<FormControl>
							<Select onChange={handleDomainChange} value={selectedDomain}>
								<option disabled value="">--- Select Domain ---</option>
								{allDomain?.map((item) => (
									<option key={item.id} value={item.domain_name}>
										{item.domain_name}
									</option>
								))}
							</Select>
						</FormControl>
					</>
					}
				</Flex>
			</Flex>
			<Flex mt={8} w={"100%"} alignItems={"end"} justifyContent={"flex-end"} gap={{ base: "10px", md: "15px" }}>
				<Button isDisabled={isLoading || (selectedDomain === "" && domainSelection === 'manually') || selectedFiles.length === 0} type="submit" id='btn' size={"sm"}>
					{isLoading ? <BarLoader color="#FFFFFF" /> : "Upload"}
				</Button>
				<Button colorScheme="red" variant={"outline"} size={"sm"} onClick={onClose}>Cancel</Button>
			</Flex>
		</form>
	)
}

export default UploadZip
