import { FadeLoader } from 'react-spinners';
import './LoadingOverlay.css';

const LoadingOverlay = ({ message = "" }) => {
	return (
		<div className="overlay">
			<div className="overlay-content">
				{/* <span className='loader'></span> */}
				<FadeLoader size={20} color='#fff' />
				<span className='loader-message'>{message}</span>
			</div>
		</div>
	)
}

export default LoadingOverlay