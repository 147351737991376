import React, { useContext, useEffect, useState } from 'react'
import ToggleSwitchStyles from "../../upload/ToggleSwitch.module.css";
import { Button, Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Skeleton, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { BsDownload } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { RiEyeLine } from 'react-icons/ri';
import { IoMdDoneAll } from 'react-icons/io';
import { interceptorsSetup } from '../../../api/intreceptor';
import { IoPricetagsOutline } from 'react-icons/io5';
import TagSearch from '../../upload/components/TagSearch';
import { CentralizeStore } from '../../../contexts/CentralizeStoreProvider';
import CustomToast from '../../../reusable-components/Toaster/CustomToast';
import viewOutput from '../../../api/viewOutput';

const SearchMenu = (props) => {
  const {selectedImage,selectCounter,onClearSelection,handleSelectAll} = props;
  const {searchRes} = useContext(CentralizeStore)

  const { isOpen: isTagSearchDrawerOpen, onOpen: onTagSearchDrawerOpen, onClose: onTagSearchDrawerClose } = useDisclosure();
  const { isOpen: isDownloadPopoverOpen, onOpen: onDownloadPopoverOpen, onClose: onDownloadPopoverClose } = useDisclosure();
  const [isMetaTagDownloading, setIsMetaTagDownloading] = useState(false);
  const toast = useToast()
  const navigate = useNavigate();

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);
    
    const downloadImage = (url, filename) => {
     const link = document.createElement('a');
         link.href = url;
         link.download = filename;
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
    };

    const handleDownloadImg = () => {
        if(!(selectCounter > 0 )) {
          return toast({
             title: `Image not select !`,
             position: "top",
             status: "warning",
             duration: 3000,
             variant: "left-accent",
             isClosable: true,
           })
         }
          const imageLinks = selectedImage.map(image => image.image_link);
          imageLinks.forEach((url, index) => {
            setTimeout(() => {
              const filename = `image_${index + 1}.webp`;
              downloadImage(url, filename);
            }, index * 500);
          });
    }

    const handleViewModel = async() => {
        if(!(selectCounter > 0 )) {
          return toast({
             title: `Image not select !`,
             position: "top",
             status: "warning",
             duration: 3000,
             variant: "left-accent",
             isClosable: true,
           })
         }
    
        const ids = selectedImage.map(image => image.image_id);
       
        let storedEmail =localStorage.getItem("email")
        const params = new URLSearchParams({
          ids: JSON.stringify(ids),
          email: storedEmail
        }).toString();
        
        navigate(`/layout/model-output?${params}`);
      }

      const handleTagSearch = () => {
        onTagSearchDrawerOpen()
      }

      const download_tags = async (typeOfFile) => {
        if(!(selectCounter > 0 )) {
          return toast({
            render: ({ onClose }) => (
              <CustomToast title={`Image not select !`} status="warning" onClose={onClose}/>
            ),
             position: "top",
             duration: 3000,
             isClosable: true,
           })
         }
        const ids = selectedImage?.map(image => image.image_id);
        const payload = {
          ids:ids,email:localStorage.getItem("email"),single_or_multiple:typeOfFile
        }
        try{
          setIsMetaTagDownloading(true)
          const response = await viewOutput.download_tags(payload);
          if(response.status === 200) {
            toast({
              render: ({ onClose }) => (
                <CustomToast title={`MetaTag downloaed successfully.`} status="success" onClose={onClose} />
              ),position: "top", duration: 3000, isClosable: true,
             })
          }
        }catch(err){
          toast({
            render: ({ onClose }) => (
              <CustomToast
                title={`${err.message}`}
                status="error"
                onClose={onClose}
              />
            ),
             position: "top",
             duration: 3000,
             isClosable: true,
           })
        }finally{
          setIsMetaTagDownloading(false)
        }
      }

  return (
    <Flex 
       flexDir={{ base: "column-reverse",sm:"row" ,md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        gap={{ base: "5px", md: "10px", lg: "17px" }}
        position={"fixed"}
        top={{base:"114px",sm:"135",md:"100px",lg:"96px"}}
        left={"0"}
        right={"0"}
        zIndex={'1'}
    >
      <Flex borderRadius={"8px"} alignItems={"center"} justifyContent={"space-around"} color={"#333333"} padding={"4px 10px"} boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px;"} bg={"#FFFFFF"} className={ToggleSwitchStyles.upLoadMenuCon} gap={"5px"}>
           {/* <Button onClick={handleTagSearch} display={"flex"} flexDir={{md:"column", lg:"row"}} gap={"7px"} colorScheme="black.500" variant="ghost">
           <IoPricetagsOutline/> Tags Search
          </Button> */}
         {/* <Button isDisabled={searchRes.length === 0} gap={"7px"} colorScheme="black.500" variant="ghost" color={selectCounter === 0 ? "#BDBDBD" : ""} onClick={handleDownloadImg}>
            <BsDownload /> Download
          </Button> */}
          <Popover isOpen={isDownloadPopoverOpen} onClose={onDownloadPopoverClose} closeOnBlur={true}>
            <PopoverTrigger>
              <Button onClick={onDownloadPopoverOpen} gap={"7px"} colorScheme="black.500" variant="ghost" display={"flex"} flexDir={{md:"column", lg:"row"}}>
                <BsDownload /><Text m={"0px"} display={{base:"none",md:"block"}}>Download</Text>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody display={"flex"} alignItems={"center"} justifyContent={"space-around"} gap={"8px"}>
                {
                  isMetaTagDownloading ? <Skeleton height="17px" width="100px"></Skeleton> : <Text onClick={() => download_tags(true)} cursor={"pointer"} as={"span"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"5px"} color={selectCounter === 0 ? "#BDBDBD" : ""}>As Single file</Text>
                }
                {
                  isMetaTagDownloading ? <Skeleton height="17px" width="100px"></Skeleton> : <Text onClick={() => download_tags(false)} cursor={"pointer"} as={"span"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"5px"} color={selectCounter === 0 ? "#BDBDBD" : ""}>As Separate file</Text>
                }
              </PopoverBody>
            </PopoverContent>
          </Popover>
           <Button onClick={handleViewModel} isDisabled={searchRes.length === 0} color={selectCounter === 0 ? "#BDBDBD" : ""} gap={"7px"} colorScheme="black.500" variant="ghost">
            <RiEyeLine /> View
          </Button>
          <Button gap={"7px"} colorScheme="black.500" variant="ghost" onClick={handleSelectAll} isDisabled={searchRes.length === 0} color={searchRes.length === 0 ? "#BDBDBD" : ""}>
            <IoMdDoneAll/> Select All
          </Button>
          
          {(selectCounter > 0 && (searchRes.length !== 0)) && (
            <Button
              borderRadius={"50px"}
              border={"1px solid grey"}
              gap={"8px"}
              colorScheme="black.500"
              variant="ghost"
              onClick={onClearSelection}
              size={"sm"}
            >
              <RxCross1 /> {selectCounter || 0} selected
            </Button>
          )}
      </Flex>
      {/* <TagSearch isTagSearchDrawerOpen={isTagSearchDrawerOpen} onTagSearchDrawerClose={onTagSearchDrawerClose}/> */}
    </Flex>
  )
}

export default SearchMenu
