import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "../../assets/styles/Navbar.module.css";
import logo from "../../assets/Images/IntelliSearch-White.svg";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Button,
  MenuDivider,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import DropZone from "../ImageDrop/DropZone";
import { MdOutlineCancel } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { OverlayContext } from "../../contexts/overlay/OverlayContextProvider";
import CreditContext from "../../contexts/creditProvider/creditContext";
import { CentralizeStore } from "../../contexts/CentralizeStoreProvider";
import configService from "../../api/configService";
import ContactModal from "./components/ContactModal";

interface NavbarProps {
  creditBalance: number; // Example type for credit balance
  onClickHandler: () => void; // Example type for click handler
}

const Navbar: React.FC<NavbarProps> = ({ creditBalance, onClickHandler }) => {
  const [isDropZoneOpen, setIsDropZoneOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { setIsBlur, toggleOverlay, handleSetBlurTrue } = useContext(OverlayContext);
  const {isCrditBalanceLoad} = useContext(CreditContext)
  const userName = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const authenticatedFrom = localStorage.getItem("authenticateFrom")
  const [uploadedImg, setUploadedImg] = useState<string | null>(null);
  const {setUploadedImage,isSearchLoading} = useContext(CentralizeStore)
  const configSetting = JSON.parse(localStorage.getItem("configSetting"))
  const [newConfigSetting, setNewConfigSetting] = useState(null);
  const { isOpen: isContactModalOpen, onOpen: onContactModalOpen, onClose: onContactModalClose } = useDisclosure();
  const navRef = useRef<HTMLDivElement>(null);
  const searchConRef = useRef<HTMLDivElement>(null); // Ref for search container
  const isSearchTextValid = searchText.length > 0;
  const Navigate = useNavigate()
  const [contactModalType, setContactModalType] = useState('');
  const [isEnterVal, setIsEnterVal] = useState(false);

  useEffect(() => {
    handleFetchLatestConfigSetting()
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target as Node) &&
        searchConRef.current &&
        !searchConRef.current.contains(event.target as Node)
      ) {
        setIsDropZoneOpen(false);
        setIsBlur(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(isSearchLoading) {
      setIsDropZoneOpen(false)
    }
  }, [isSearchLoading])

  const toggleDropZone = () => {
    setIsDropZoneOpen((prevState) => !prevState);
    toggleOverlay();
  };

  const handleOpenFileUpload = () => {
    handleSetBlurTrue();
    setIsDropZoneOpen(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && searchText.length > 0 && !isSearchLoading) {
      e.preventDefault();
      setIsEnterVal(true)
    }else{
      setIsEnterVal(false)
    }
  };

  const clearSearch = () => {
    setUploadedImg(null);
    toggleDropZone()
    setSearchText("")
    // setUploadedImage(null)
  }

  const handleFetchLatestConfigSetting = async () => {
    try{
      const response = await configService.getConfigSetting();
      setNewConfigSetting(response.configuration_data)
    }catch(err){
      console.log("err while getting config setting: ", err)
    }
  }

  const handleContactModalOpen = (type) => {
    setContactModalType(type)
    onContactModalOpen()
  }

  return (
    <Box justifyContent={(configSetting?.Intelli_Search ||newConfigSetting?.Intelli_Search === true)  ? "space-evenly" : "space-between"} id={Styles.Nav} ref={navRef}>
      <div className={Styles.logoCon}>
        <Link to="/layout">
          <img src={logo} alt="logo" className={Styles.nav_logo}/>
        </Link>
      </div>
      {(configSetting?.Intelli_Search === true || newConfigSetting?.Intelli_Search === true) &&
      <Flex
        flexDir={"column"}
        w={{ base: "auto", md: "49%", lg: "49%" }}
        ref={searchConRef}
      >
        {/* <span className={Styles.search_heading}>
          Searching into: <span id={Styles.search_subHeading}>My files</span>
        </span> */}
        <div
          className={`${Styles.search_con} ${
            isDropZoneOpen ? Styles.open : ""
          }`}
        >
          <IoSearch
            cursor={"pointer"}
            onClick={handleOpenFileUpload}
            fontSize={"19px"}
          />
          <input
            onClick={handleOpenFileUpload}
            type="text"
            placeholder="Search by the item description"
            value={searchText}
            className={Styles.search_inp}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <MdOutlineCancel
            cursor={"pointer"}
            onClick={clearSearch}
            fontSize={"20px"}
            className={`${Styles.CancelDropZone} ${
              isDropZoneOpen ? Styles.Cancel : ""
            }`}
          />
          <div
            className={`${Styles.dropZone} ${
              isDropZoneOpen ? Styles.open : ""
            }`}
          >
            <DropZone
              setIsDropZoneOpen={setIsDropZoneOpen}
              searchText={searchText}
              setSearchText={setSearchText}
              isSearchTextValid={isSearchTextValid}
              setUploadedImg={setUploadedImg}
              uploadedImg={uploadedImg}
              isEnterVal={isEnterVal}
              setIsEnterVal={setIsEnterVal}
            />
          </div>
        </div>
      </Flex>
      }
      <Flex>
      <Menu>
        <MenuButton as={Button} rounded={"full"} variant={"none"} cursor={"pointer"} minW={0}><GoQuestion color="#FFFFFF"/></MenuButton>
        <MenuList zIndex="2">
          <MenuGroup>
            <MenuItem onClick={() => handleContactModalOpen('Feedback')}>Feedback</MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => handleContactModalOpen('Support')}>Support</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"none"}
          cursor={"pointer"}
          minW={0}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={{ base: "5px", sm: "7px", md: "9px", lg: "11px" }}
            className={Styles.my_profile}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              className={Styles.profile_con}
              // bg={"#f0f0f0"}
            >
              {/* <img
                src="https://img.freepik.com/premium-photo/young-woman-yellow-coat-eyeglasses-with-lights_912214-2940.jpg?w=740"
                alt="dp"
                className={Styles.my_dp}
              /> */}
              <h5 style={{margin: 0, color:"#FFFFFF"}}>{userName?.split(" ")?.[0]?.[0]}{userName?.split(" ")?.[1]?.[0]}</h5>
            </Flex>
            <Box
              display={{ base: "none", md: "flex" }}
              flexDir={"column"}
              className={Styles.about_profile}
            >
              <Flex alignItems={"center"} gap={2} justifyContent={"flex-start"}>
                <span className={Styles.Hello}>Hello</span>{" "}
                <img
                  src="https://em-content.zobj.net/thumbs/160/apple/325/waving-hand_1f44b.png"
                  alt="hi"
                  className={Styles.hi}
                />{" "}
              </Flex>
              <Flex alignItems={"center"} gap={2}>
                <span className={Styles.profile_name}>{userName}</span>{" "}
                <FaAngleDown className={Styles.Hello} />{" "}
              </Flex>
              {
                isCrditBalanceLoad ? <Skeleton width="65px" height="14px"></Skeleton> :  <Flex alignItems={"center"} gap={2} justifyContent={"flex-start"}>
                <span className={Styles.Hello}>
                  Balance:
                </span>{" "}
                <span className={Styles.Hello} style={{ fontSize: "13px" }}>
                  {creditBalance?.toFixed(1)}
                </span>
              </Flex>
              }
              
            </Box>
          </Flex>
        </MenuButton>
        <MenuList zIndex="2">
          <MenuGroup>
            <MenuItem display={{ base: "block", md: "none" }}>
              <Flex alignItems={"center"} gap={2} justifyContent={"flex-start"}>
                <span style={{ color: "#AAAAAA" }} className={Styles.Hello}>
                  Balance:
                </span>{" "}
                <span style={{ color: "#AAAAAA", fontSize: "13px" }}>
                  {creditBalance.toFixed(1)}
                </span>
              </Flex>
            </MenuItem>
            {
              authenticatedFrom == "Custom" && (<><NavLink to="changepassword"> <MenuItem>Change Password</MenuItem> </NavLink>
              <MenuDivider /> </>)
            }
            {(role === "Admin" || role === "superAdmin") && (
              <>
                <NavLink to="users">
                  <MenuItem>Users</MenuItem>
                </NavLink>
                <MenuDivider />
                <NavLink to="department">
                  <MenuItem>Department</MenuItem>
                </NavLink>
                <MenuDivider />
                {/* <NavLink to="taxonomy">
                  <MenuItem>Taxonomy</MenuItem>
                </NavLink>
                <MenuDivider /> */}
                {/* <NavLink to="transaction">
                  <MenuItem>Transaction</MenuItem>
                </NavLink>
                <MenuDivider /> */}
              </>
            )}
                {role == "superAdmin" && (
                <>
                <NavLink to="taxonomy">
                  <MenuItem>Taxonomy</MenuItem>
                </NavLink>
                <MenuDivider />
                </>
                )}
                <NavLink to="transaction">
                  <MenuItem>Transaction</MenuItem>
                </NavLink>
                <MenuDivider />

            {role == "superAdmin" && (
              <>
                <NavLink to="configuration">
                  <MenuItem>Configuration</MenuItem>
                </NavLink>
                <MenuDivider />
              </>
            )}

            <MenuItem onClick={onClickHandler}>Logout</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      </Flex>
      <ContactModal onOpen={onContactModalOpen} isOpen={isContactModalOpen} onClose={onContactModalClose} type={contactModalType}/>
    </Box>
  );
};

export default Navbar;
